
// `px`s are used for grid breakpoints and container widths because
// the viewport width is in pixels and doesn't change with the font size.
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

/** @function
 * @name rem
 * Convert pixels to rems
 * @param {Number} - pixel value
 * @param {Number} - rem value (Default is 16px)
 */
@function rem($pxval, $base: 16) {
  @return ($pxval / $base) * 1rem;
}

@function transition-default() {
  @return all .2s $animation-curve;
}
