.Wang {
  height: rem(35);
  fill: $white;
  transition: transition-default();

  &:hover {
    fill: $yellow;
  }

  svg {
    height: 100%;
  }
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 55px;
  background-color: #000;

  .nav a {
    font-size: .9rem;
  }
}

// 576
@media screen and (min-width: map-get($breakpoints, sm)) {
  .App-header {
    height: 90px;

    .nav a {
      padding: 0 1rem;
    }
  }

  .headroom--unfixed {
    .App-header {
      height: 90px;

      .nav a {
        font-size: .95rem;
      }
    }
  }
}
