.btn {
  display: inline-block;
  border: rem(2) solid $blue;
  padding: rem(9) rem(20);
  text-align: center;
  background-color: transparent;
  color: $blue;
  font-weight: 600;
  border-radius: rem(2);
  transition: transition-default();

  &:hover,
  &:focus {
    color: $white;
    background-color: $blue;
  }

  &:active,
  &:focus {
    outline: 0;
  }
}
