.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  a {
    padding: 0 .5rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $nav-link-color;

    &:hover {
      color: $nav-link-hover-color;
    }

    &.active {
      color: $green;
    }
  }
}
