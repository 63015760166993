@font-face {
  font-family: 'a';
  font-weight: 400;
  src: url('./static/fonts/Apercu.woff2') format('woff2'),
    url('./static/fonts/Apercu.woff') format('woff');
}

@font-face {
  font-family: 'a';
  font-weight: 400;
  font-style: italic;
  src: url('./static/fonts/Apercu-Italic.woff2') format('woff2'),
    url('./static/fonts/Apercu-Italic.woff') format('woff');
}

// medium
@font-face {
  font-family: 'a';
  font-weight: 500;
  src: url('./static/fonts/Apercu-Medium.woff2') format('woff2'),
    url('./static/fonts/Apercu-Medium.woff') format('woff');
}

// bold
@font-face {
  font-family: 'a';
  font-weight: 700;
  src: url('./static/fonts/Apercu-Bold.woff2') format('woff2'),
    url('./static/fonts/Apercu-Bold.woff') format('woff');
}

// monospace
@font-face {
  font-family: 'm';
  src: url('./static/fonts/ApercuPro-Mono.woff2') format('woff2'),
    url('./static/fonts/ApercuPro-Mono.woff') format('woff');
}

@import '../node_modules/normalize.css/normalize.css';

@import './static/scss/vars';
@import './static/scss/utils';
@import './static/scss/layout';
@import './static/scss/typography';
@import './static/scss/jumbotron';
@import './static/scss/chips';
@import './static/scss/figures';
@import './static/scss/labels';
@import './static/scss/blockrevealer';
@import './static/scss/images';
@import './static/scss/buttons';
@import './static/scss/nav';
@import './static/scss/icons';
@import './static/scss/animatedsvgs';
@import './App';

// import component SCSS
@import './static/scss/components/Header';
@import './static/scss/components/Home';
@import './static/scss/components/Portfolio';
@import './static/scss/components/Contact';
@import './static/scss/components/Project';
@import './static/scss/components/CTA';
@import './static/scss/components/FourOhFour';
@import './static/scss/components/Footer';
