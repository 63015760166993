.section {
  margin: rem(25) 0 0;

  .svg-container {
    display: block;

    svg {
      max-height: 8rem;
    }
  }

  .copy {
    padding-top: .5rem;
  }
}

// Technical Expertise section
#skills {
  ul {
    padding-left: 1rem;
    list-style-type: none;
  }
}

// 576
@media screen and (min-width: map-get($breakpoints, sm)) {
  .section {
    display: flex;
    align-items: center;

    .svg-container {
      width: 35%;

      svg {
        max-height: 9rem;
      }
    }

    .copy {
      width: 65%;
      margin: 0;
      padding: 0 0 0 1rem;
    }
  }
}

// 768
@media screen and (min-width: map-get($breakpoints, md)) {
  #skills {
    display: flex;
    justify-content: space-between;

    ul {
      padding: 0;
    }

    .fadein-animate {
      width: 33%;
    }
  }

  .section {
    padding: 1rem;
  }
}

// 992
// @media screen and (min-width: map-get($breakpoints, lg)) {}

// 1200
// @media screen and (min-width: map-get($breakpoints, xl)) {}
