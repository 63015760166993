$black: #000;
$white: #fff;

$dark-background: #1a1a1a;
$light-background: #fafafa;

$text-color: rgba($black, .87);
$secondary-text-color: rgba($black, .54);

$blue: #1100af;
$green: #00b259;
$yellow: #ffd464;
$violet: #c392ff;
$CTA: #86eaef;

// link colors
$link-color: $blue;
$link-hover-color: #0d30ff;
$link-active-color: #bc001c;

$nav-link-color: $white;
$nav-link-hover-color: $yellow;

$animation-curve: cubic-bezier(.4,0,.2,1);
