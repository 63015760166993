.chip {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 2rem;
  margin: .25rem;
  padding: 0 .75rem;
  background-color: lightgray;
  border-radius: 2rem;

  &--avatar {
    padding-left: 2.5rem;
  }

  &__content {
    font-size: .9rem;
  }
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  border: 0;
  border-radius: 50%;
  background-color: $yellow;
  text-align: center;
  text-transform: uppercase;

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 1.5rem;
  }
}
