.fof {
  position: relative;
  min-height: calc(100vh - 55px);
  background-color: $dark-background;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $green;
  }
}
