.project-details {
  display: flex;
  justify-content: space-between;
  flex-flow: column wrap;

  &__section {
    padding: .5rem 0;

    hr {
      margin-bottom: 2.25rem;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: .3125rem 0;

    .btn {
      margin-right: .5em;
      margin-bottom: .5em;
    }
  }
}

// 576
@media screen and (min-width: map-get($breakpoints, sm)) {
  .project-details {
    flex-direction: row;
  }
}

// 768
@media screen and (min-width: map-get($breakpoints, md)) {
  #tech {
    width: 50%;
  }
}

// 992
// @media screen and (min-width: map-get($breakpoints, lg)) {}

// 1200
// @media screen and (min-width: map-get($breakpoints, xl)) {}
