.tiles {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
}

.project-tile {
  background-color: $dark-background;
  margin-top: .5rem;
  transition: transition-default();

  a {
    display: block;
    height: 100%;
    padding: 2rem;
    color: $white;
    transition: transition-default();

    h4 {
      color: $violet;
      transition: transition-default();
    }

    &:hover {
      color: $link-color;

      h4 {
        color: $link-color;
      }
    }
  }

  &:hover {
    background-color: $light-background;
  }
}

// 576
// @media screen and (min-width: map-get($breakpoints, sm)) {}

// 768
@media screen and (min-width: map-get($breakpoints, md)) {
  .tiles {
    flex-flow: row wrap;
  }

  .project-tile {
    width: calc(50% - .5rem);
  }
}

// 992
// @media screen and (min-width: map-get($breakpoints, lg)) {}

// 1200
@media screen and (min-width: map-get($breakpoints, xl)) {
  .project-tile {
    width: calc((100% / 3) - .5rem);
  }
}
