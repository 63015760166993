.form-control {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 1rem;

  input,
  textarea {
    width: 100%;
    margin-top: .5rem;
    padding: .5rem;
    border: 2px solid lightgray;
    border-radius: 2px;
    font-family: 'm', monospace;
    outline: none;
    box-shadow: none;
    transition: transition-default();

    &:focus {
      border-color: $blue;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .form-control {
    width: 65%;
  }
}
