.jumbotron {
  display: flex;
  align-items: center;
  min-height: 20rem;
  padding: rem(30) 0;
}

.landing {
  padding: rem(40) 0;
  background-size: 40%;
}

// 576
@media screen and (min-width: map-get($breakpoints, sm)) {
  .landing {
    min-height: 30rem;
    background-size: 30%;
  }
}

// 768
@media screen and (min-width: map-get($breakpoints, md)) {
  .landing {
    background-size: 20%;
  }
}

// 992
@media screen and (min-width: map-get($breakpoints, lg)) {
  .landing {
    min-height: 35rem;
    background-size: 15%;
  }

  .blurb {
    max-width: 50vw;
  }
}

// 1200
@media screen and (min-width: map-get($breakpoints, xl)) {
  .jumbotron {
    min-height: 30rem;
  }

  .landing {
    min-height: calc(100vh - 100px);
  }

  .jumbotron h1,
  .blurb {
    padding: 0 .5rem;
    background-color: rgba($white, .74);
  }
}
