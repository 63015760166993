.label {
  display: inline-block;
  background-color: $yellow;
  padding: 0 .375rem;
  font-weight: 500;
  font-size: 1.25rem;
  color: rgba($black, .74);
  letter-spacing: .06rem;
  text-transform: uppercase;
}
