.figure {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  figcaption {
    font-style: italic;
    text-align: center;
    color: $secondary-text-color;
  }
}
